import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { SeriesList } from './series-list';

const SeriesStyled = styled.ul`
  margin: 4rem 0;
  list-style-type: none;

  > li {
    margin-top: 4rem;
    border-top: 1px dotted rgba(242, 19, 104, 0.4);
  }
  h1 {
    margin: 4rem 0 0;
  }
  section {
    border-top: 0;
    margin-top: 2rem;
  }
  ul {
    list-style-type: none;
  }
`;

export function AllSeriesLists() {
  return (
    <StaticQuery
      query={graphql`
        {
          allMarkdownRemark(limit: 2000, filter: { frontmatter: { draft: { ne: true } } }) {
            group(field: frontmatter___series___name) {
              fieldValue
              totalCount
              nodes {
                excerpt(pruneLength: 120)
                fields {
                  slug
                  readingTime {
                    text
                  }
                }
                frontmatter {
                  date(formatString: "MMMM Do YYYY")
                  title
                  series {
                    name
                    label
                    master
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark: { group } }) => (
        <SeriesStyled>
          {group.map((seriesData, index) => (
            <li key={index}>
              <h1>{fetchSeriesLabel(seriesData)}</h1>
              <SeriesList series={seriesData.nodes} mainPage={true} />
            </li>
          ))}
        </SeriesStyled>
      )}
    />
  );
}

function fetchSeriesLabel(seriesData) {
  return seriesData.nodes
    .filter(node => node.frontmatter.series.master === true)
    .map(node => {
      return node.frontmatter.series.label;
    });
}
