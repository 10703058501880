import React from 'react';
import { AllSeriesLists } from '../components/all-series-lists';
import Layout from '../components/layout';

const SeriesPage = () => (
  <Layout>
    <h1>All Series</h1>
    <p>
      Listed below are all the posts that have been gathered together as a series of posts or because they are closely
      related to each other in subject.
    </p>
    <AllSeriesLists />
  </Layout>
);

export default SeriesPage;
